<template>
    <div class="filter-wrapper">
        <div style="display: flex; align-items: center; height: 36px">
            <div>
                <el-dropdown @command="handleChangeCourseType">
                    <span class="el-dropdown-link">
                        {{ queryList.courseType | courseType
                        }}<i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="2"
                            >教师课表</el-dropdown-item
                        >
                        <el-dropdown-item command="1"
                            >班级课表</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="line"></div>
            <div v-show="queryList.courseType == '1'">
                <span class="label"></span>
                <cascader
                    :key="formData.defaultclassid"
                    style="width: 200px"
                    :defaultclassid="formData.defaultclassid"
                    :data="formData.data[0]"
                    @changeCascader="changeCascader"
                >
                </cascader>
            </div>
            <div
                :class="['select-week', {current: queryList.courseType == '3'}]"
            >
                <span class="label"></span>
                <el-date-picker
                    style="width: 200px"
                    v-model="queryList.selectDay"
                    type="date"
                    :clearable="false"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </div>
            <div
                class="select-status"
                v-show="queryList.courseType !== '3'"
            >
                <div
                    v-for="item of statusList"
                    :key="item.id"
                    :class="['item', {current: item.id == queryList.dayWeek}]"
                    @click="handlerChangeStatus(item)"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import ElSelectTree from "el-select-tree";
Vue.use(ElSelectTree);

import virtualList from "vue-virtual-scroll-list";
import ElOptionNode from "./VirtulaList/ElOptionNode.vue";
import SelectTree from "./Sub/SelectTree";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
import Cascader from "./Cascaderview/Cascader";

import CourseSchedule from "@/filters/CourseSchedule.js";
export default {
    name: "TeachingResearchCourseScheduleTempFilterWrapper",
    filters: CourseSchedule,
    components: {
        ElSelectTree,
        "virtual-list": virtualList,
        SelectTree,
        ExpandFilter,
        Cascader,
    },
    props: {
        queryList: Object,
        formData: Object,
        statusList: Array,
        schedulingClassesList: Array,
        teacherList: Array,
    },
    data() {
        return {
            itemComponent: ElOptionNode,
        };
    },
    mounted() {
        // console.log("1642-----周二",this.formData)
    },
    methods: {
        handleChangeCourseType(e) {
            // console.log("周二下拉菜单",e)
            this.$emit("handleChangeCourseType", e);
        },
        handlerChangeStatus(data) {
            this.$emit("handlerChangeStatus", data);
        },
        handlerGradeChange() {
            this.$emit("handlerGradeChange");
        },
        // 选中年级班级
        changeCascader(data) {
            // console.log("选择的年纪班级",data)
            this.$emit("changeCascader", data);
            data.ref.dropDownVisible = false;
        },
        // 筛选条件-选择时间
        datePickerBlur(value) {
            this.$emit("datePickerBlur", value);
        },
        checkTime(startTime, endTime) {
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true;
            let endFullYear = end.getFullYear();
            let startFullYear = start.getFullYear();
            let endMonth = end.getMonth();
            let startMonth = start.getMonth();

            if (endFullYear != startFullYear) {
                if (endMonth + 12 - startMonth > 6) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    status = false;
                }

                if (endMonth + 12 - startMonth == 6) {
                    if (end.getDate() > start.getDate()) {
                        this.$message.error("开始时间和结束时间不能超过6个月");
                        status = false;
                    }
                }
            }
            if (end.getMonth() - start.getMonth() > 6) {
                this.$message.error("开始时间和结束时间不能超过6个月");
                return (status = false);
            } else if (end.getMonth() - start.getMonth() == 6) {
                if (end.getDate() > start.getDate()) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    return (status = false);
                }
            }
            return status;
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    // height: 36px;
    // height: 100px;
    // background-color: red;
    .el-dropdown-link {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
        .el-icon-caret-bottom {
            color: #878787;
        }
    }
    .line {
        width: 1px;
        height: 24px;
        margin: 0 24px;
        background-color: #dbdbdb;
    }
    .select-class {
        display: flex;
        align-items: center;
        .label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #878787;
            margin-right: 10px;
        }
    }
    .select-week {
        display: flex;
        align-items: center;
        .label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #878787;
            margin-right: 10px;
        }
    }
    .select-week.current {
        margin-right: auto;
    }
    .select-status {
        display: flex;
        width: 68px;
        height: 32px;
        align-items: center;
        box-sizing: border-box;
        padding: 3px;
        background: #f2f2f2;
        border-radius: 6px;
        margin-left: 24px;
        margin-right: auto;
        .item {
            width: 30px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            border-radius: 2px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a6a6a6;
            cursor: pointer;
        }
        .item.current {
            background-color: #fff;
            color: #595959;
        }
    }
}
</style>
